<template>
  <section class="invoice-preview-wrapper">
    <b-row
      v-if="invoiceData"
      class="invoice-preview"
    >

      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <h4 class="invoice-title">
                  {{ $t('general.order') }}
                  <span class="invoice-number">#{{ invoiceData.id }}</span>
                </h4>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    {{ $t('general.created_at') }}:
                  </p>
                  <p class="invoice-date">
                    {{ invoiceData.created_at }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <hr class="invoice-spacing">

          <b-card-body
            class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">

              <b-col
                cols="12"
                md="3"
                class="p-0"
              >
                <h6 class="mb-2">
                  {{ $t('general.buyer') }}:
                </h6>
                <h6 class="mb-25">
                  {{ invoiceData.user.name }}
                </h6>
                <p class="card-text mb-25">
                  {{ invoiceData.user.phone }}
                </p>
                <p class="card-text mb-25">
                  {{ invoiceData.user.email }}
                </p>
              </b-col>

              <b-col
                v-if="invoiceData.i_not_receive"
                cols="12"
                md="3"
                class="p-0"
              >
                <h6 class="mb-2">
                  {{ $t('general.recipient') }}:
                </h6>
                <h6 class="mb-25">
                  {{ invoiceData.recipient.first_name }} {{ invoiceData.recipient.last_name }} {{ invoiceData.recipient.middle_name }}
                </h6>
                <p class="card-text mb-25">
                  {{ invoiceData.recipient.phone }}
                </p>
              </b-col>

              <b-col
                cols="12"
                md="3"
                class="p-0"
              >
                <div v-if="invoiceData.address !== null || invoiceData.warehouse !== null || invoiceData.shop !== null">
                  <h6 class="mb-2">
                    {{ $t('orders.delivery') }}:
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          {{ $t('orders.delivery_method') }}:
                        </td>
                        <td><span class="font-weight-bold">{{ invoiceData.deliveryMethod.title }}</span></td>
                      </tr>
                      <tr>
                        <td v-if="invoiceData.deliveryMethod.key === 'self_pickup'">
                          {{ invoiceData.shop.title }}
                        </td>
                        <td v-if="invoiceData.deliveryMethod.key === 'courier'">
                          {{ invoiceData.address.street }} {{ invoiceData.address.number }} {{ invoiceData.address.flat }} {{ invoiceData.address.entrance }}
                        </td>
                        <td v-if="invoiceData.deliveryMethod.key === 'nova_poshta'">
                          {{ invoiceData.city.title }}, {{ invoiceData.warehouse.title }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>

              <b-col
                cols="12"
                md="3"
                class="p-0"
              >
                <div>
                  <h6 class="mb-2">
                    {{ $t('orders.payment') }}:
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          {{ $t('orders.payment_method') }}:
                        </td>
                        <td><span class="font-weight-bold">{{ invoiceData.paymentMethod.title }}</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <b-table-lite
            responsive
            :items="invoiceData.products"
            :fields="productTableColumns"
          >
            <template #cell(product)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.product.title }}
              </b-card-text>
            </template>

            <template #cell(price)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.price }} {{ $t('general.currencies.uah') }}
              </b-card-text>
            </template>

            <template #cell(sale_price)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.sale_price }} {{ $t('general.currencies.uah') }}
              </b-card-text>
            </template>
          </b-table-lite>

          <hr class="invoice-spacing">

          <b-card-body class="invoice-padding pb-0">
            <b-row>

              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                &nbsp;
              </b-col>

              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ $t('orders.subtotal') }}:
                    </p>
                    <p class="invoice-total-amount">
                      {{ invoiceData.totals[invoiceData.totals.findIndex(item => item.type === 'sub_total')].amount }} {{ $t('general.currencies.uah') }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ $t('orders.discount') }}:
                    </p>
                    <p class="invoice-total-amount">
                      {{ invoiceData.totals[invoiceData.totals.findIndex(item => item.type === 'discount')].amount }} {{ $t('general.currencies.uah') }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ $t('orders.delivery') }}:
                    </p>
                    <p class="invoice-total-amount">
                      {{ invoiceData.totals[invoiceData.totals.findIndex(item => item.type === 'delivery')].amount }} {{ $t('general.currencies.uah') }}
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ $t('orders.total') }}:
                    </p>
                    <p class="invoice-total-amount">
                      {{ invoiceData.totals[invoiceData.totals.findIndex(item => item.type === 'total')].amount }} {{ $t('general.currencies.uah') }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">{{ $t('orders.call_me') }}: </span>
            <span>{{ invoiceData.call_me ? $t('general.yes') : $t('general.no') }}</span>
          </b-card-body>
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">{{ $t('general.comment') }}: </span>
            <span>{{ invoiceData.comment || '-' }}</span>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="changeStatus"
          >
            {{ $t('orders.change_status') }}
          </b-button>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            {{ $t('general.print') }}
          </b-button>
        </b-card>
        <b-card>
          <b-table-lite
            responsive
            :items="invoiceData.history"
            :fields="historyTableColumns"
          >
            <template #cell(status)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ $t(`orders.statuses.${data.item.status}`) }}
              </b-card-text>
            </template>
          </b-table-lite>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import SweetAlertMixin from '@/mixins/SweetAlertMixin'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
  },
  mixins: [SweetAlertMixin],
  data() {
    return {
      invoiceData: null,
      productTableColumns: [
        { key: 'id', label: this.$t('administration.table.fields.id'), sortable: false },
        { key: 'price', label: this.$t('administration.table.fields.price'), sortable: false },
        { key: 'product', label: this.$t('administration.table.fields.title'), sortable: false },
        { key: 'quantity', label: this.$t('administration.table.fields.quantity'), sortable: false },
        { key: 'sale_price', label: this.$t('administration.table.fields.sale_price'), sortable: false },
      ],
      historyTableColumns: [
        { key: 'created_at', label: this.$t('administration.table.fields.created_at'), sortable: false },
        { key: 'status', label: this.$t('administration.table.fields.status'), sortable: false },
      ],
    }
  },
  beforeCreate() {
    this.$http.get(`/api/administration/orders/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.invoiceData = data
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.invoiceData = null
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'orders-index' })
        }
      })
  },
  methods: {
    changeStatus() {
      this.confirm(result => {
        this.$http.post(`/api/administration/orders/${router.currentRoute.params.id}/status`, { status: result.value })
          .then(() => {
            this.$http.get(`/api/administration/orders/${router.currentRoute.params.id}`)
              .then(response => {
                const { data } = response.data

                this.invoiceData = data
              })
              .catch(error => {
                if (error.response.status === 404) {
                  this.invoiceData = null
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: this.$t('notifications.not_found'),
                      icon: 'AlertCircleIcon',
                      variant: 'warning',
                    },
                  })
                  router.replace({ name: 'orders-index' })
                }
              })
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('notifications.successfully'),
                text: this.$t('notifications.success.updated'),
                icon: 'CoffeeIcon',
                variant: 'success',
              },
            })
          })
      }, {
        input: 'select',
        inputOptions: {
          new: this.$t('orders.statuses.new'),
          paid: this.$t('orders.statuses.paid'),
          completed: this.$t('orders.statuses.completed'),
          cancel: this.$t('orders.statuses.cancel'),
          delivering: this.$t('orders.statuses.delivering'),
        },
        text: this.$t('notifications.confirm.update.text'),
      })
    },
    printInvoice() {
      window.print()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
